@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://use.fontawesome.com/releases/v5.7.2/css/all.css");

// Your variable overrides
$primary: #002147;

@import "node_modules/bootstrap/scss/bootstrap";

body {
  font-family: "Lato", sans-serif;
}

h1 {
  font-weight: 800;
  line-height: 48px;
  margin-bottom: 20px;
}

p {
  margin-bottom: 30px;
  line-height: 25px;
}

a {
  font-weight: 800;
}

.row {
  padding: 0px 20px;
}

.home-banner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)),
    url("./images/home.jpg");
  background-position: top;
  background-size: cover;
  color: white;
  align-items: center;
}

.home-banner .content {
  height: 100%;
  flex: 0 0 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}

.home-banner .content .image-wrapper {
  flex-grow: 1;
  padding: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.home-banner .content img {
  width: 100%;
  margin: auto;
}

.home-banner .content .scroll-more {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  animation: pulsate 2s ease-in 1s 3;

  -webkit-animation: pulsate 2s ease-in 1s 3;
  opacity: 1;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home-banner .content .scroll-more p {
  margin-bottom: 5px;
}

.card {
  margin: 20px 0;
}

.card img {
  object-fit: cover;
}

.card-title {
  font-size: 22px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 50px 0;
  text-align: center;
}

.footer img {
  width: 100px;
}

.film-wrapper {
  width: 100%;
  height: 500px;
  position:relative;
}

.film-wrapper iframe {
  position:absolute;
  width:100%;
  height:100%;
}

.social-icons {
  font-size: 50px;
  letter-spacing: 14px;
}
